/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback } from 'react';
import { useAppContext } from '../../context/AppContext';
import { formatZincToJson } from '../../utils/formatZinc';

import { PanelPoint } from './PanelPoint';
import EditSvg from '@mui/icons-material/Edit';
import CloseSvg from '@mui/icons-material/Close';
import SaveSvg from '@mui/icons-material/Save';
import { useInterval } from '../../utils/useInterval';
import { Button } from '@vscom/components';
import { Grid, IconButton, Paper, Typography } from '@mui/material';
import { arrayMove } from '@vscom/utils';
import { toast } from 'react-toastify';
import { dbApiUpdateItem } from '../../api/crudCalls';
import { hsApiWatchList, hsApiWatchPoll, hsApiWatchSub } from '../../api/watch';
import { NavData, Point } from '../../types';
import { buildPanelName } from '../../utils/panelsIndexBuilder';
import { hsApiGetNavById } from '../../api/nav';

const LEASE = 60;

type PanelCardProps = {
  id: string | number;
  title: string;
  points: Point[];
  cleanPoints: Point[];
  clickEdit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  forceUpdate: () => void;
  disabled?: boolean;
  index: number;
};

export const PanelCard: React.FC<PanelCardProps> = ({
  id,
  title,
  points,
  cleanPoints,
  clickEdit,
  forceUpdate,
  disabled,
  index,
}) => {
  const [state, setState] = useAppContext();
  const [error, setError] = useState(false);
  const [bottomActionsVisibility, setBottomActionsVisibility] = useState(false);
  const [panelPoints, setPanelPoints] = useState(cleanPoints);
  const [watchId, setWatchId] = useState(null);
  const navId = points?.[0]?.navId?.split('-')[0];

  useEffect(() => {
    if (
      (!watchId || state.closedPanelWatchId === watchId) &&
      cleanPoints.length !== panelPoints.length
    ) {
      watchSubCall(cleanPoints);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleanPoints, state.closedPanelWatchId]);

  useEffect(() => {
    if (!watchId || state.closedPanelWatchId === watchId) {
      watchSubCall(cleanPoints);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  const savePointsOrder = async () => {
    await dbApiUpdateItem(
      'panels',
      {
        name: buildPanelName(title, index),
        points: JSON.stringify(points),
        cleanPoints: JSON.stringify(panelPoints),
      },
      id
    );

    setBottomActionsVisibility(false);
    refreshData();
  };

  const refreshData = () => {
    forceUpdate();
  };

  const getAllHsNavData = useCallback(async () => {
    const siteNavData = await hsApiGetNavById(state.hsServerUrl, navId);
    const hsNavData: NavData[] = formatZincToJson(siteNavData).rows;

    return hsNavData;
  }, [state.hsServerUrl, navId]);

  const watchSubCall = useCallback(
    async (selectedPoints: Point[], renewWatch = false) => {
      try {
        const allHsNavData: NavData[] = await getAllHsNavData();

        // Create a set of current point IDs for quick lookup
        const currentPointIds = new Set(
          allHsNavData.map((point: any) => point.id.val)
        );

        // Filter out points that are no longer present
        const validPoints = selectedPoints.filter((point) =>
          currentPointIds.has(point.id.val)
        );

        let pointsWithIdsOnly = validPoints
          .map((point: Point) => `@${point.id.val}`)
          .join('\n');

        let hsBody = `ver:"2.0" watchDis:"${title}" lease:${LEASE}\nid\n`;
        hsBody += pointsWithIdsOnly;
        hsBody += '\n';
        if (renewWatch) {
          hsBody = `ver:"2.0" watchId:"${watchId}" lease:${LEASE}\nid\n`;
        }

        const response = await hsApiWatchSub(hsBody, state.hsServerUrl);

        const resFirstLine = response.split('\n')[0];
        const resSecondLine = response.split('\n')[1];

        // Check if watch sub res is empty
        if (resSecondLine !== '' && resSecondLine !== 'empty') {
          const watchSubRes = formatZincToJson(response);

          setWatchId(watchSubRes.meta.watchId);
          setPanelPoints(watchSubRes.rows);

          if (error) {
            setError(false);
          }
        }
        if (resFirstLine.includes('errTrace')) {
          const errorRes = formatZincToJson(response);

          setError(true);
          console.error(`watch sub error : ${errorRes.meta.errTrace}`);
        }
      } catch (error) {
        console.error(error);
        toast.error('Watch sub error: request syntax is wrong.');
      }
    },
    [error, state.hsServerUrl, watchId, title, getAllHsNavData]
  );

  const watchListCall = useCallback(async () => {
    const response = await hsApiWatchList(state.hsServerUrl);
    const resSecondLine = response.split('\n')[1];
    return resSecondLine === '' || resSecondLine === 'empty';
  }, [state.hsServerUrl]);

  const watchPollCall = useCallback(async () => {
    let hsBody = `ver:"2.0" watchId:"${watchId}"\nid,cur\n`;
    const response = await hsApiWatchPoll(hsBody, state.hsServerUrl);

    const resFirstLine = response.split('\n')[0];
    const resSecondLine = response.split('\n')[1];

    // Check if watch poll res is empty
    if (resSecondLine !== '' && resSecondLine !== 'empty') {
      const watchPollRes = formatZincToJson(response);

      let changedPoints = [...panelPoints];
      changedPoints.map((panelPoint) => {
        watchPollRes.rows.forEach((changedPoint: any) => {
          if (panelPoint.id.val === changedPoint.id.val) {
            panelPoint.curVal = changedPoint.curVal;
          }
        });
      });

      if (changedPoints.length) {
        setPanelPoints(changedPoints);
      }

      if (error) {
        setError(false);
      }
    } else if (resFirstLine.includes('errTrace')) {
      if (resSecondLine === 'empty') {
        watchSubCall(panelPoints, false);
      } else {
        const errorRes = formatZincToJson(response);
        console.error(`watch poll error : ${errorRes.meta.dis}`);
        setError(true);
      }
    }
  }, [watchId, panelPoints, error, state.hsServerUrl, watchSubCall]);

  const clickEditWithWatchId = (event: any) => {
    setState({ panelModalWatchId: watchId });

    clickEdit(event);
  };

  const movePoint = (dragIndex: any, hoverIndex: any) => {
    setPanelPoints(arrayMove([...panelPoints], dragIndex, hoverIndex));
    setBottomActionsVisibility(true);
  };

  useInterval(
    () => {
      if (watchId) {
        watchPollCall();
      }
    },
    2000,
    [watchId, watchPollCall]
  ); // Watch poll every 2s

  useInterval(
    async () => {
      const isWatchListEmpty: boolean = await watchListCall();
      watchSubCall(cleanPoints, !isWatchListEmpty);
    },
    (LEASE - 10) * 1000,
    [cleanPoints, watchSubCall, watchId]
  );

  return (
    <Paper style={{ padding: 15, overflow: 'hidden' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 15,
            }}
          >
            <span>{title}</span>
            {!state.isReader && !disabled && (
              <IconButton onClick={clickEditWithWatchId}>
                <EditSvg />
              </IconButton>
            )}
          </Typography>
        </Grid>

        {!error &&
          panelPoints.map((selectedPoint: any, index: number) => (
            <Grid key={index} item xs={12}>
              <PanelPoint
                selectedPoint={selectedPoint}
                index={index}
                watchPollCall={watchPollCall}
                movePoint={movePoint}
                disabled={disabled}
              />
            </Grid>
          ))}
        {(!panelPoints || error) && (
          <Grid item xs={12}>
            <Typography color="error">
              Haystack server not responding, please make sure a server url is
              provided.
            </Typography>
          </Grid>
        )}
        {bottomActionsVisibility && (
          <>
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                startIcon={<CloseSvg />}
                type="button"
                color="error"
                onClick={() => setBottomActionsVisibility(false)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                fullWidth
                carrier
                startIcon={<SaveSvg />}
                type="button"
                onClick={savePointsOrder}
              >
                Save point order
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};
