import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import updateBreadcrumb from '../utils/updateBreadcrumb';
import { Breadcrumb } from '../components/layout/Breadcrumb';
import { PanelModal } from '../components/modals/PanelModal';
import { PanelCard } from '../components/panel/PanelCard';
import PlusSvg from '@mui/icons-material/Add';
import { Button } from '@vscom/components';
import { Grid, Tooltip, Typography } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useBoolean } from '@vscom/utils';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { useAppContext } from '../context/AppContext';
import { dbApiGetItem } from '../api/crudCalls';
import { IndexedPanel, Panel } from '../types';
import { Sort } from '@mui/icons-material';
import { SortModal } from '../components/modals/SortModal';
import { getPanelName } from '../utils/panelsIndexBuilder';

export const ViewDetailPage = () => {
  let { projectid, mappingid = '', viewid } = useParams();
  const [state, setState] = useAppContext();

  const [viewName, setViewName] = useState<string>('');
  const [panelsData, setPanelsData] = useState<Panel[]>([]);
  const [panel, setPanel] = useState<Panel>();
  const { value: isLarge, toggle: toggleLarge } = useBoolean(false);

  const toggleSort = () =>
    setState({ sortModalVisible: !state.sortModalVisible });

  const fetchViewDetail = async () => {
    setState({ loading: true });

    const panelResponse = await dbApiGetItem('panels', String(viewid));
    // Use getPanelName to extract index and sort panels
    const sortedPanels: IndexedPanel[] = panelResponse.result
      .map((panel: Panel, idx: number) => ({
        ...panel,
        ...getPanelName(panel.Name),
        originalIndex: idx, // Preserve original order if no index is present
      }))
      .sort((a: IndexedPanel, b: IndexedPanel) => a.index - b.index);

    setPanelsData(sortedPanels);

    const viewResponse = await dbApiGetItem(
      'views/view-detail',
      String(viewid)
    );
    setViewName(viewResponse.result[0].Name);

    setState({ loading: false });
    return viewResponse;
  };
  useEffect(() => {
    updateBreadcrumb(
      state.breadcrumbItems,
      setState,
      projectid,
      mappingid,
      viewid
    );
    fetchViewDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addPanel = () => {
    setState({
      panelModalVisible: true,
    });
    setPanel(undefined);
  };

  const clickEdit = (event: any, panel: Panel) => {
    event.preventDefault();
    setState({
      panelModalVisible: true,
    });
    setPanel(panel);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid container spacing={3} style={{ marginBottom: 80 }}>
        <Grid item xs={12}>
          <Breadcrumb breadcrumbItems={state.breadcrumbItems} />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h4">{viewName}</Typography>
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          justifyContent="flex-end"
          alignItems={'center'}
        >
          <Tooltip title={'Sort Panels'}>
            <>
              <Button
                variant="outlined"
                disabled={!panelsData}
                style={{ marginRight: 10 }}
                onClick={toggleSort}
              >
                <Sort />
              </Button>
            </>
          </Tooltip>
          <Tooltip
            title={isLarge ? 'Switch to 3 columns' : 'Switch to 2 columns'}
          >
            <>
              <Button
                variant="outlined"
                style={{ marginRight: 10 }}
                onClick={toggleLarge}
              >
                {!isLarge ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
              </Button>
            </>
          </Tooltip>
          {!state.isReader && (
            <Button carrier startIcon={<PlusSvg />} onClick={addPanel}>
              Add panel
            </Button>
          )}
        </Grid>

        {panelsData &&
          panelsData.map((panel: Panel, index: number) => (
            <PanelCardWrapper
              key={index}
              panel={panel}
              index={index}
              clickEdit={clickEdit}
              fetchViewDetail={fetchViewDetail}
              isLarge={isLarge}
            />
          ))}
      </Grid>
      {panelsData && (
        <SortModal onClose={fetchViewDetail} panelsData={panelsData} />
      )}
      <PanelModal
        parentViewId={viewid}
        panel={panel}
        panelsData={panelsData}
        forceUpdate={fetchViewDetail}
      />
    </DndProvider>
  );
};

const PanelCardWrapper = ({
  panel,
  index,
  clickEdit,
  fetchViewDetail,
  isLarge,
}: {
  panel: Panel;
  index: number;
  clickEdit: (event: any, panel: Panel) => void;
  fetchViewDetail: () => void;
  isLarge: boolean;
}) => {
  const points = JSON.parse(panel.Points);
  const cleanPoints = JSON.parse(panel.CleanPoints);
  const handleEdition = (event: any) => {
    clickEdit(event, panel);
  };

  return (
    <Grid item key={'PanelCard-' + panel.PanelID + index} xs={isLarge ? 6 : 4}>
      <PanelCard
        id={panel.PanelID}
        index={index}
        title={getPanelName(panel.Name)?.Name ?? ''}
        points={points}
        cleanPoints={cleanPoints}
        clickEdit={handleEdition}
        forceUpdate={fetchViewDetail}
      />
    </Grid>
  );
};
