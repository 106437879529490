import React, { useRef } from 'react';
import { useAppContext } from '../../context/AppContext';
import { getConversion, getLabel } from '../../utils/unitConversion';

import { useDrag } from 'react-dnd';
import { formatZincToJson } from '../../utils/formatZinc';

import DragHandleSvg from '@mui/icons-material/DragIndicator';
import InformationSvg from '@mui/icons-material/InfoOutlined';
import { NumberSlider } from './NumberSlider';
import { StatusReadOnly } from './StatusReadOnly';
import { StatusSwitch } from './StatusSwitch';
import { Tooltip, Typography, Zoom } from '@mui/material';
import { NumberDisplay } from './NumberDisplay';
import { HexView } from './HexView';
import { BinView } from './BinView';
import { useDropSetup } from '@vscom/utils';
import { toast } from 'react-toastify';
import { hsApiPointWrite } from '../../api/watch';

type Props = {
  selectedPoint: any;
  index: number;
  watchPollCall: any;
  movePoint: any;
  disabled?: boolean;
};

export const PanelPoint: React.FC<Props> = ({
  selectedPoint,
  index,
  watchPollCall,
  movePoint,
  disabled,
}) => {
  const [state] = useAppContext();
  const pointRef = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);

  if (String(selectedPoint.unit) === '0') {
    selectedPoint.unit = '';
  }
  const selectedPointConverted: any =
    getConversion(selectedPoint.curVal, selectedPoint.unit, state.units) ??
    'NO UNIT';
  const unitLabel = selectedPointConverted.unit
    ? getLabel(selectedPointConverted.unit)
    : null;
  const unitValue = +selectedPointConverted?.value;

  const minValue = getConversion(
    selectedPoint.minVal,
    selectedPoint.unit,
    state.units
  )?.value;
  const maxValue = getConversion(
    selectedPoint.maxVal,
    selectedPoint.unit,
    state.units
  )?.value;

  // ============== DRAG & DROP ==============
  // init the drag & drop system
  const [{ opacity }, drag, preview] = useDrag({
    type: 'POINT',
    item: () => {
      return { type: 'POINT', index };
    },
    collect: (monitor: any) => ({
      opacity: monitor.isDragging() ? 1 : 1,
    }),
  });
  const [{ handlerId }, drop] = useDropSetup({
    type: 'POINT',
    previewRef: pointRef,
    index,
    moveObject: movePoint,
  });
  drag(dragRef);
  drop(preview(pointRef));
  // ============== END DRAG & DROP ==============

  const pointWriteCall = async (hsBody: any) => {
    try {
      const response = await hsApiPointWrite(hsBody, state.hsServerUrl);
      const resFirstLine = response.split('\n')[0];
      if (resFirstLine.includes('errTrace')) {
        const errorRes = formatZincToJson(response);

        toast.error(errorRes.meta.dis);
      } else {
        toast.info('Value updated');
      }

      watchPollCall();
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
    }
  };

  return (
    <div
      data-handler-id={handlerId}
      ref={pointRef}
      style={{
        opacity,
        display: 'flex',
        height: 20,
        justifyContent: 'space-between',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {!disabled && (
          <div
            ref={dragRef}
            data-handler-id={handlerId}
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'move',
            }}
          >
            <DragHandleSvg key={'DragHandleSvg-' + selectedPoint.id.val} />
          </div>
        )}
        <Tooltip
          arrow
          TransitionComponent={Zoom}
          placement="right"
          title={
            <div style={{ fontSize: 16 }}>
              <p>
                <strong>Channel: </strong>
                {selectedPoint.channel}
              </p>
              <p>
                <strong>id: </strong>
                {selectedPoint.id.val}
              </p>
              <p>
                <strong>minVal: </strong>
                {Number(minValue)}
              </p>
              <p>
                <strong>maxVal: </strong>
                {Number(maxValue)}
              </p>
            </div>
          }
        >
          <InformationSvg />
        </Tooltip>
        <Typography variant="body1" style={{ marginLeft: 5 }}>
          {selectedPoint.dis}
        </Typography>
      </div>
      {!(selectedPoint.kind === 'Number' && selectedPoint.writable) && (
        <div
          style={{
            borderBottom: '1px dashed grey',
            flexGrow: 1,
          }}
        />
      )}
      {selectedPoint.kind === 'Number' &&
        !['Hex', 'Bin'].includes(selectedPoint.disFmt) &&
        selectedPoint.writable && (
          <NumberSlider
            id={selectedPoint.id.val}
            min={Number(minValue)}
            max={Number(maxValue)}
            value={unitValue}
            unit={unitLabel}
            disFmt={selectedPoint.disFmt}
            selectedPoint={selectedPoint}
            pointWriteCall={pointWriteCall}
            disabled={disabled}
            key={'NumberSlider-' + selectedPoint.id.val}
          />
        )}
      {selectedPoint.disFmt === 'Hex' && (
        <HexView
          id={selectedPoint.id.val}
          value={unitValue}
          pointWriteCall={pointWriteCall}
          disabled={disabled}
          key={'HexView-' + selectedPoint.id.val}
        />
      )}
      {selectedPoint.disFmt === 'Bin' && (
        <BinView
          id={selectedPoint.id.val}
          value={unitValue}
          bitFields={selectedPoint.bitFields}
          pointWriteCall={pointWriteCall}
          disabled={disabled}
          key={'BinView-' + selectedPoint.id.val}
        />
      )}
      {selectedPoint.kind === 'Bool' && !selectedPoint.writable && (
        <StatusReadOnly status={unitValue} />
      )}
      {selectedPoint.kind === 'Bool' && selectedPoint.writable && (
        <StatusSwitch
          key={'StatusSwitch-' + selectedPoint.id.val}
          id={selectedPoint.id.val}
          status={unitValue}
          disabled={disabled}
          pointWriteCall={pointWriteCall}
        />
      )}
      {selectedPoint.kind === 'Number' &&
        !selectedPoint.writable &&
        !['Hex', 'Bin'].includes(selectedPoint.disFmt) && (
          <NumberDisplay
            key={'NumberDisplay-' + selectedPoint.id.val}
            value={unitValue}
            unit={unitLabel}
            disFmt={selectedPoint.disFmt}
          />
        )}
    </div>
  );
};

export default PanelPoint;
